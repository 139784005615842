import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import { alpha, useTheme } from '@mui/system'
import { Link } from 'react-router-dom'

import Relation from 'components/Relation'

import g1 from './images/1.jpg'
import g2 from './images/2.jpg'
import g3 from './images/3.jpg'
import g4 from './images/4.jpg'
import g5 from './images/5.jpg'
import g6 from './images/6.jpg'

const images = [g1, g2, g3, g4, g5, g6]

const RelacjaWydarzeniaBanner = () => {
  const theme = useTheme()

  const isMD = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Relation
      attention="Spotkanie Ogólnopolskie!"
      background={alpha(theme.palette.primary.main, 1)}
      images={images}
    >
      <Typography
        color="white"
        fontWeight={900}
        textAlign={isMD ? 'center' : 'left'}
        variant="h2"
      >
        Warszawa, 11.05.2024{' '}
      </Typography>
      <Typography
        color="white"
        textAlign={isMD ? 'center' : 'left'}
        variant="subtitle2"
      >
        11 maja 2024 roku odbyło się kolejne w roku formacyjnym 2023/2024
        spotkanie ogólnopolskie ⚔️ Wojowników Maryi ⚔️.
      </Typography>
      <Typography
        color="white"
        textAlign={isMD ? 'center' : 'left'}
        variant="subtitle2"
      >
        Tym razem kilka tysięcy mężczyzn wraz z rodzinami przyjechało do
        Świątyni Opatrzności Bożej w warszawskim Wilanowie. Mszy Świętej
        przewodniczył ks. Piotr Pączkowski SDB.
      </Typography>
      <Typography
        color="white"
        textAlign={isMD ? 'center' : 'left'}
        variant="subtitle2"
      >
        Zapraszamy do obejrzenia krótkiej relacji z wydarzenia:{' '}
        <a
          href="https://www.youtube.com/watch?v=9p5_4Yssb1U"
          rel="noopener noreferrer"
          target="_blank"
        >
          kliknij tutaj!
        </a>
      </Typography>
      <Typography
        color="white"
        textAlign={isMD ? 'center' : 'left'}
        variant="body2"
      >
        fot. Michał Siermiński (WM Ursynów)
      </Typography>
      <Box
        display="flex"
        justifyContent={isMD ? 'center' : 'start'}
      >
        <Link to="/wydarzenia">
          <Button
            color="secondary"
            size="large"
            variant="outlined"
          >
            Inne wydarzenia
          </Button>
        </Link>
      </Box>
    </Relation>
  )
}

export default RelacjaWydarzeniaBanner
