import { Helmet } from 'react-helmet'

import KimJestWojownikMaryi from 'pages/components/KimJestWojownikMaryi'

import FormacjaBanner from './FormacjaBanner'
import KodexMainBanner from './KodexMainBanner'
import OgolnopolskieBanner from './OgolnopolskieBanner'
import RegionalneBanner from './RegionalneBanner'

const KodexPage = () => {
  return (
    <>
      <Helmet>
        <title>Kodex | Wojownicy Maryi</title>
        <meta
          name="description"
          content="Kodex | Wojownicy Maryi"
        />
      </Helmet>
      <KodexMainBanner />
      <KimJestWojownikMaryi />
      <OgolnopolskieBanner />
      <FormacjaBanner />
      <RegionalneBanner />
    </>
  )
}

export default KodexPage
