import { REGIONS } from 'consts/regions'
import { Group } from 'types/group'

const groups: Group[] = [
  {
    id: 'debno',
    name: 'Dębno',
    email: 'wojownicymaryi.debno@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_SZCZECINSKO_KAMIENSKA,
  },
  {
    id: 'glogow',
    name: 'Głogów',
    email: 'wojownicymaryi.glogow@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_SZCZECINSKO_KAMIENSKA,
  },
  {
    id: 'gryfice',
    name: 'Gryfice',
    email: 'wojownicymaryi.gryfice@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_SZCZECINSKO_KAMIENSKA,
  },
  {
    id: 'lubrza',
    name: 'Lubrza',
    email: 'wojownicymaryi.lubrza@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_SZCZECINSKO_KAMIENSKA,
  },
  {
    id: 'pila',
    name: 'Piła',
    email: 'wojownicymaryi.pila@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_SZCZECINSKO_KAMIENSKA,
  },
  {
    id: 'slupsk',
    name: 'Słupsk',
    email: 'wojownicymaryi.slupsk@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_SZCZECINSKO_KAMIENSKA,
  },
  {
    id: 'szczecin',
    name: 'Szczecin',
    email: 'wojownicymaryi.szczecin@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_SZCZECINSKO_KAMIENSKA,
  },
  {
    id: 'zagan',
    name: 'Żagań',
    email: 'wojownicymaryi.zagan@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_SZCZECINSKO_KAMIENSKA,
  },
]

export default groups
