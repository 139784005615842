import { Event } from 'types/event'

import FestiwalDlaZycia from './images/festiwal-dla-zycia.jpeg'
import LetnieForum from './images/forum-charyzmatyczne.jpeg'
import Ogolnopolskie from './images/spotkanie-ogolnopolskie.jpeg'

const events: Event[] = [
  {
    id: 'letnie-forum-charyzmatyczne',
    name: 'Letnie forum charyzmatyczne - "Podnieście głowy i nabierzcie ducha"',
    attention: 'Zapraszamy!',
    location:
      'Kirchplatz 1, 56341 Kamp-Bornhofen Sanktuarium Matki Bożej Bolesnej',
    startDate: '08-06-2024',
    startTime: '10:00',
    description: 'Szczegóły na plakacie.',
    shortDescription: 'Szczegóły na plakacie',
    image: LetnieForum,
  },
  {
    id: 'festiwal-dla-zycia',
    name: 'Festiwal Dla Życia',
    attention: 'Dołącz do nas!',
    location: 'Gdańsk, Bazylika Mariacka',
    startDate: '16-06-2024',
    startTime: '15:00',
    description: 'Szczegóły na plakacie.',
    shortDescription: 'Szczegóły na plakacie',
    image: FestiwalDlaZycia,
  },
  {
    id: 'spotkanie-ogolnopolskie-bydgoszcz',
    name: 'Pasowanie na Wojownika Maryi - Spotkanie Ogólnopolskie (Bydgoszcz)',
    attention: 'Zapraszamy!',
    location: 'Bydgoszcz',
    startDate: '15-06-2024',
    startTime: '10:00',
    description: 'Szczegóły na plakacie.',
    shortDescription: 'Szczegóły na plakacie',
    image: Ogolnopolskie,
  },
]

export default events
