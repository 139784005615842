export const REGIONS = {
  METROPOLIA_WROCLAWSKA: 'metropolia-wroclawska',
  METROPOLIA_WARSZAWSKA: 'metropolia-warszawska',
  METROPOLIA_WARMINSKA: 'metropolia-warminska',
  METROPOLIA_SZCZECINSKO_KAMIENSKA: 'metropolia-szczecinsko-kamienska',
  METROPOLIA_PRZEMYSKA: 'metropolia-przemyska',
  METROPOLIA_POZNANSKA: 'metropolia-poznanska',
  METROPOLIA_LUBELSKA: 'metropolia-lubelska',
  METROPOLIA_LODZKA: 'metropolia-lodzka',
  METROPOLIA_KRAKOWSKA: 'metropolia-krakowska',
  METROPOLIA_GNIEZNIENSKA: 'metropolia-gnieznienska',
  METROPOLIA_GDANSKA: 'metropolia-gdanska',
  METROPOLIA_CZESTOCHOWSKA: 'metropolia-czestochowska',
  METROPOLIA_BIALOSTOCKA: 'metropolia-bialostocka',
  GRUPY_ZAGRANICZNE: 'grupy-zagraniczne',
}
