import { Event } from 'types/event'

import NieBedzieszMialBogowImage from './images/nie-bedziesz-mial-bogow.jpeg'
import WarszawaUrsynowRegionalne from './images/warszawa-ursynow-regionalne.jpg'
import ZalozPelnaZbrojeBozaImage from './images/zaloz-pelna-zbroje-boza.jpeg'

const events: Event[] = [
  {
    id: 'wm-ursynow-regionalne',
    name: 'Wojownicy Maryi Ursynów - Spotkanie Regionalne',
    attention: 'Dołącz do nas!',
    location: 'Parafia Św. Tomasza, ul. Dereniowa 12, Warszawa',
    startDate: '03-01-2024',
    startTime: '18:30',
    description: 'Szczegóły na plakacie.',
    shortDescription: 'Szczegóły na plakacie',
    image: WarszawaUrsynowRegionalne,
    groupId: 'warszawa-ursynow',
  },
  {
    id: 'nie-bedziesz-mial-bogow-cudzych-przede-mna-hierarchia-wartosci-czlowieka-wierzacego',
    name: 'Nie będziesz miał bogów cudzych przede mną - Hierarchia wartości człowieka wierzącego',
    attention: 'Zapraszamy do Wiednia!',
    location: 'Pfarre Am Schüttel, Rustenschacheralle 14, 1020 Wiedeń',
    startDate: '10-01-2024',
    startTime: '19:00',
    description: 'Szczegóły na plakacie.',
    shortDescription: 'Szczegóły na plakacie',
    image: NieBedzieszMialBogowImage,
  },
  {
    id: 'zaloz-pelna-zbroje-boza-spotkanie-ogolnopolskie-elk',
    name: 'Załóż Pełną Zbroję Bożą! - Spotkanie Ogólnopolskie (Ełk)',
    attention: 'Zapraszamy!',
    location:
      'Parafia pw. Św. Rafała Kalinowskiego, Jana Pawła II 6, 19-300 Ełk',
    startDate: '13-01-2024',
    startTime: '09:00',
    description: 'Szczegóły na plakacie',
    shortDescription: 'Szczegóły na plakacie',
    image: ZalozPelnaZbrojeBozaImage,
  },
]

export default events
