import { REGIONS } from 'consts/regions'
import { Group } from 'types/group'

const groups: Group[] = [
  {
    id: 'grodzisk-wielkopolski',
    name: 'Grodzisk Wielkopolski',
    email: 'wojownicymaryi.grodziskwlkp@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_POZNANSKA,
  },
  {
    id: 'ostrow-wielkopolski',
    name: 'Ostrów Wielkopolski',
    email: 'wojownicymaryi.ostrowwlkp@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_POZNANSKA,
  },
  {
    id: 'poznan',
    name: 'Poznań',
    email: 'wojownicymaryi.poznan@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_POZNANSKA,
  },
  {
    id: 'tarnowo-podgorne',
    name: 'Tarnowo Podgórne',
    email: 'wojownicymaryi.tarnowopodgorne@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_POZNANSKA,
  },
]

export default groups
