import { Event } from 'types/event'

import JestemMezczyzna from './images/jestem-mezczyzna.jpeg'
import Ogolnopolskie from './images/ogolnopolskie-warszawa.jpeg'

const events: Event[] = [
  {
    id: 'spotkanie-ogolnopolskie-rodzina-ulmow',
    name: 'Spotkanie Ogólnopolskie "Rodzina Ulmów - Miłość Przekraczająca Śmierć!"',
    attention: 'Dołącz do nas!',
    location:
      'Świątynia Opatrzności Bożej w Wilanowie, ul. Prymasa Augusta Hlonda 1, Warszawa',
    startDate: '11-05-2024',
    startTime: '10:00',
    description: (
      <>
        <p>PLAN:</p>
        <p>09:00 - 10:00 - zbiórka przy Kościele św. Tomasza (ul. Dereniowa)</p>
        <p>10:00 - rozpoczęcie spotkania</p>
        <p>10:15 - start procesj różańcowej ulicami Ursynowa i Wilanowa</p>
        <p>12:00 - Eucharystia z konferencją w Świątyni Opatrzności Bożej</p>
        <p>
          13:30 - przekazanie Płomienia Miłości dla Rodzin (z konferencją ks.
          Dariusza Dąbrowskiego)
        </p>
        <p>14:30 - Adoracja Najświętszego Sakramentu</p>
        <p>15:00 - Koronka do Bożego Miłosierdzia</p>
        <p>15:30 - część integracyjna</p>
        <p>17:30 - zakończenie spotkania</p>
      </>
    ),
    shortDescription: 'Szczegóły na plakacie',
    image: Ogolnopolskie,
  },
  {
    id: 'jestem-mezczyzna-znam-swoje-miejsce',
    name: 'Jestem Mężczyzną, Znam Swoje Miejsce - Forum',
    attention: 'Dołącz do nas!',
    location: 'Częstochowa, ul. Krakowska 1, Aula Parafii św. Zygmunta',
    startDate: '25-05-2024',
    startTime: '08:00',
    description: 'Szczegóły na plakacie.',
    shortDescription: 'Szczegóły na plakacie',
    image: JestemMezczyzna,
  },
]

export default events
