import {
  Autocomplete,
  Box,
  Container,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import 'dayjs/locale/pl'
import type { SyntheticEvent } from 'react'
import { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

import events from 'data/events'
import groups from 'data/groups'

import Event from './Event'

const EventsPage = () => {
  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  const groupSlugQueryParam = searchParams.get('group')

  const groupFromParams = groups?.find(
    (group) => group.id === groupSlugQueryParam
  )

  const mappedGroups = useMemo(() => {
    return groups?.map((group) => group.name) || []
  }, [])

  const navigateWithParams = ({
    groupParam,
  }: {
    groupParam?: string | null
  }) => {
    const params: {
      group?: string
    } = {}

    if (groupParam) {
      params.group = groupParam
    }

    navigate({
      pathname: '/wydarzenia',
      search: createSearchParams(params).toString(),
    })
  }

  const handleGroupSelect = (
    _e: SyntheticEvent<Element>,
    value: string | null
  ) => {
    const matchedGroup = groups?.find((group) => group.name === value)

    if (matchedGroup) {
      navigateWithParams({
        groupParam: matchedGroup.id,
      })
    } else {
      navigate('/wydarzenia')
    }
  }

  const filteredEvents = events?.filter((event) => {
    const isUpToDate = dayjs(event.startDate, 'DD-MM-YYYY').isAfter(Date.now())

    if (groupSlugQueryParam === null && isUpToDate) {
      return event
    }

    return event.groupId === groupSlugQueryParam && isUpToDate
  })

  return (
    <>
      <Helmet>
        <title>Wydarzenia | Wojownicy Maryi</title>
        <meta
          name="description"
          content="Wojownicy Maryi"
        />
      </Helmet>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="pl"
      >
        <Container maxWidth="xl">
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            py={20}
            gap={3}
          >
            <Typography
              fontFamily="Oswald"
              fontWeight={900}
              textAlign="center"
              variant="h3"
            >
              Nadchodzące wydarzenia
            </Typography>
            <Typography
              maxWidth="md"
              textAlign="center"
              variant="body2"
            >
              Serdecznie zapraszamy do wzięcia udziału w nadchodzących
              wydarzeniach organizowanych lub współorganizowanych przez
              wspólnotę Wojowników Maryi!
            </Typography>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <Autocomplete
                  fullWidth
                  noOptionsText="Brak wyników"
                  onChange={handleGroupSelect}
                  options={mappedGroups}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Grupa"
                    />
                  )}
                  value={groupFromParams?.name || null}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
            >
              {filteredEvents?.map((event) => (
                <Grid
                  item
                  key={event.id}
                  xs={12}
                  sm={6}
                  lg={12}
                >
                  <Event event={event} />
                </Grid>
              ))}
            </Grid>

            {filteredEvents?.length === 0 && (
              <Typography
                mt={5}
                textAlign="center"
              >
                Nie znaleziono żadnych wydarzeń dla tych kryteriów wyszukiwania.
              </Typography>
            )}
          </Box>
        </Container>
      </LocalizationProvider>
    </>
  )
}

export default EventsPage
