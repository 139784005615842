import { Group } from 'types/group'

import GrupyZagraniczne from './grupy-zagraniczne'
import MetropoliaBialostocka from './metropolia-bialostocka'
import MetropoliaCzestochowska from './metropolia-czestochowska'
import MetropoliaGdanska from './metropolia-gdanska'
import MetropoliaGnieznienska from './metropolia-gnieznienska'
import MetropoliaKrakowska from './metropolia-krakowska'
import MetropoliaLodzka from './metropolia-lodzka'
import MetropoliaLubelska from './metropolia-lubelska'
import MetropoliaPoznanska from './metropolia-poznanska'
import MetropoliaPrzemyska from './metropolia-przemyska'
import MetropoliaSzczecinskoKamienska from './metropolia-szczecinsko-kamienska'
import MetropoliaWarminska from './metropolia-warminska'
import MetropoliaWarszawska from './metropolia-warszawska'
import MetropoliaWroclawska from './metropolia-wroclawska'

const groups: Group[] = [
  ...MetropoliaWroclawska,
  ...MetropoliaWarszawska,
  ...MetropoliaWarminska,
  ...MetropoliaSzczecinskoKamienska,
  ...MetropoliaPrzemyska,
  ...MetropoliaPoznanska,
  ...MetropoliaLubelska,
  ...MetropoliaLodzka,
  ...MetropoliaKrakowska,
  ...MetropoliaGnieznienska,
  ...MetropoliaGdanska,
  ...MetropoliaCzestochowska,
  ...MetropoliaBialostocka,
  ...GrupyZagraniczne,
]

export default groups
