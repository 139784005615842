import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { Container } from '@mui/system'
import { find, sortBy } from 'lodash'
import { SyntheticEvent, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'

import { REGIONS } from 'consts/regions'
import groups from 'data/groups'
import regions from 'data/regions'
import WMSmall from 'images/wojownicymaryi-small.jpeg'
import type { Region } from 'types/region'

const initialRegion = regions.find(
  (region) => region.id === REGIONS.METROPOLIA_WARSZAWSKA
) as Region

const GroupsPage = () => {
  const [selectedRegion, setSelectedRegion] = useState<Region>(initialRegion)

  const mappedRegions = useMemo(() => {
    return regions.map((region) => region.name) || []
  }, [])

  const selectedRegionGroups = useMemo(() => {
    return sortBy(
      groups.filter((group) => group.regionId === selectedRegion?.id),
      'image'
    ).reverse()
  }, [selectedRegion])

  const handleRegionSelect = (
    _e: SyntheticEvent<Element>,
    value: string | null
  ) => {
    if (!value) {
      setSelectedRegion(initialRegion)
    }

    const region = find(regions, { name: value }) as Region | null

    if (region) {
      setSelectedRegion(region)
    }
  }

  return (
    <>
      <Helmet>
        <title>Grupy regionalne | Wojownicy Maryi</title>
        <meta
          name="description"
          content="Wojownicy Maryi"
        />
      </Helmet>
      <Container maxWidth="xl">
        <Box
          display="flex"
          flexDirection="column"
          gap={5}
          py={20}
        >
          <Typography
            fontFamily="Oswald"
            fontWeight={900}
            textAlign="center"
            variant="h3"
          >
            Grupy regionalne
          </Typography>
          <Typography
            textAlign="center"
            variant="body2"
          >
            Oprócz spotkań ogólnopolskich, odbywają się też regionalne spotkania
            organizowane przez Wojowników Maryi. Są one utrzymane w
            charakterystyce i charyzmacie wspólnoty i są zakładane i prowadzone
            przez wcześniej przygotowanych liderów, którymi są członkowie
            wspólnoty - którzy przeszli formacje, są po Pasowaniu oraz dodatkowo
            zostali do tej funkcji przygotowani.
          </Typography>
          <Typography
            fontWeight={700}
            textAlign="center"
            variant="body2"
          >
            Na spotkania organizowane przez Wojowników Maryi, zapraszamy
            mężczyzn w wieku 18-50 lat.
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
          >
            <Autocomplete
              fullWidth
              noOptionsText="Brak wyników"
              onChange={handleRegionSelect}
              options={mappedRegions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Region"
                />
              )}
              sx={{
                maxWidth: 'sm',
              }}
              value={selectedRegion.name}
            />
          </Box>
          <Typography
            fontSize={30}
            fontWeight={500}
            variant="h3"
            textAlign="center"
          >
            Lider: {selectedRegion?.leaderName}
          </Typography>
          <Grid
            container
            spacing={3}
          >
            {selectedRegionGroups?.map((group) => (
              <Grid
                item
                key={group.id}
                xs={12}
                sm={6}
                md={6}
                lg={3}
              >
                <Card
                  sx={{
                    borderRadius: 3,
                    boxShadow: 5,
                    transition: 'transform .3s',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                  }}
                >
                  <CardMedia
                    image={group.image || WMSmall}
                    sx={{ height: 200 }}
                  />
                  <CardContent>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography
                        fontFamily="Oswald"
                        textAlign="center"
                        variant="body1"
                      >
                        {group.name}
                      </Typography>
                      <Tooltip
                        placement="top"
                        title={group.email}
                      >
                        <Typography
                          fontSize={16}
                          textAlign="center"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                          variant="body2"
                        >
                          {group.email}
                        </Typography>
                      </Tooltip>
                      {group.website && (
                        <Button
                          disabled={!group.website}
                          href={group.website || ''}
                          rel="noopener noreferrer"
                          target="_blank"
                          variant="outlined"
                        >
                          Odwiedź stronę
                        </Button>
                      )}
                      {!group.website && (
                        <Button
                          href={`mailto:${group.email}`}
                          rel="noopener noreferrer"
                          target="_blank"
                          variant="outlined"
                        >
                          Kontakt
                        </Button>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </>
  )
}

export default GroupsPage
