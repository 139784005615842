import { REGIONS } from 'consts/regions'
import { Group } from 'types/group'

import BialystokImage from './images/bialystok.jpeg'
import CzyzewImage from './images/czyzew.jpeg'
import GrajewoImage from './images/grajewo.jpeg'
import LomzaImage from './images/lomza.jpeg'
import OstrolekaImage from './images/ostroleka.jpeg'
import ZambrowImage from './images/zambrow.jpeg'

const groups: Group[] = [
  {
    id: 'bialystok',
    name: 'Białystok',
    email: 'wojownicymaryi.bialystok@gmail.com',
    website: '',
    image: BialystokImage,
    regionId: REGIONS.METROPOLIA_BIALOSTOCKA,
  },
  {
    id: 'czyzew',
    name: 'Czyżew',
    email: 'grzegorzskorupski2@wp.pl',
    website: '',
    image: CzyzewImage,
    regionId: REGIONS.METROPOLIA_BIALOSTOCKA,
  },
  {
    id: 'grajewo',
    name: 'Grajewo',
    email: 'wojownicymaryi.grajewo@gmail.com',
    website: '',
    image: GrajewoImage,
    regionId: REGIONS.METROPOLIA_BIALOSTOCKA,
  },
  {
    id: 'lapy',
    name: 'Łapy',
    email: 'krzysztofserwin@interia.pl',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_BIALOSTOCKA,
  },
  {
    id: 'lomza',
    name: 'Łomża',
    email: 'wojownicymaryi.lomza@gmail.com',
    website: '',
    image: LomzaImage,
    regionId: REGIONS.METROPOLIA_BIALOSTOCKA,
  },
  {
    id: 'ostroleka',
    name: 'Ostrołęka',
    email: 'wojownicymaryi.ostroleka@gmail.com',
    website: '',
    image: OstrolekaImage,
    regionId: REGIONS.METROPOLIA_BIALOSTOCKA,
  },
  {
    id: 'wyszkow',
    name: 'Wyszków',
    email: 'wojownicymaryi.wyszkow@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_BIALOSTOCKA,
  },
  {
    id: 'zambrow',
    name: 'Zambrów',
    email: 'wojownicymaryi.zambrow@gmail.com',
    website: '',
    image: ZambrowImage,
    regionId: REGIONS.METROPOLIA_BIALOSTOCKA,
  },
  {
    id: 'olszewo-borki',
    name: 'Olszewo-Borki',
    email: 'wojownicymaryi.ostroleka@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_BIALOSTOCKA,
  },
]

export default groups
