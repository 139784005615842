import {
  alpha,
  Box,
  Button,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { motion } from 'framer-motion'
import { FaAngleDoubleDown } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import { SlideIn } from 'components/animations'
import X from 'images/x.jpg'

const KodexMainBanner = () => {
  const theme = useTheme()

  const isSM = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Container
      maxWidth={false}
      sx={{
        background: `linear-gradient(to bottom, ${alpha(
          theme.palette.common.black,
          0.4
        )}, ${alpha(theme.palette.common.black, 0.4)}), url(${X})`,
        backgroundAttachment: isSM ? 'initial' : 'fixed',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        height: isSM ? 'unset' : '100vh',
        minHeight: isSM ? '100vh' : 'unset',
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
        }}
      >
        <Box
          alignItems="center"
          display="flex"
          height="100%"
        >
          <SlideIn type="left">
            <Stack
              spacing={3}
              py={isSM ? 15 : 0}
            >
              <Typography
                color="white"
                fontWeight={900}
                textAlign={isSM ? 'center' : 'left'}
                variant="h1"
              >
                Kodex <br />
                Wojownika Maryi
              </Typography>
              <Typography
                color="white"
                component="p"
                fontFamily="Oswald"
                textAlign={isSM ? 'center' : 'left'}
                variant="body1"
              >
                By stać się Wojownikiem Maryi, trzeba przejść przez formację
                podczas cyklicznych, organizowanych przez wspólnotę spotkań
                zarówno ogólnopolskich jak i regionalnych, oraz po uprzedniej
                weryfikacji, przystąpić do uroczystego zawierzenia tzw.
                Pasowania Mieczy, które odbywa się raz w roku.
              </Typography>
              <Box
                display="flex"
                justifyContent={isSM ? 'center' : 'start'}
              >
                <Link to="/grupy">
                  <Button
                    color="secondary"
                    size="large"
                    variant="outlined"
                  >
                    Dołącz do wspólnoty
                  </Button>
                </Link>
              </Box>
              {isSM && (
                <Box
                  display="flex"
                  justifyContent="center"
                >
                  <motion.div
                    animate={{
                      y: 40,
                    }}
                    transition={{
                      ease: 'easeInOut',
                      duration: 0.5,
                      repeatType: 'reverse',
                      repeat: Infinity,
                    }}
                  >
                    <FaAngleDoubleDown
                      color="white"
                      fontSize={36}
                    />
                  </motion.div>
                </Box>
              )}
            </Stack>
          </SlideIn>
        </Box>
      </Container>
    </Container>
  )
}

export default KodexMainBanner
