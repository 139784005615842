import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Box, Container, Stack } from '@mui/system'

import { SlideIn } from 'components/animations'
import Plakat from 'images/o-doswiadczeniu.png'

const BookAdvert = () => {
  const theme = useTheme()

  const isMD = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Container maxWidth="xl">
      <Box py={15}>
        <Grid
          container
          spacing={8}
        >
          <Grid
            item
            xs={12}
            lg={6}
          >
            <SlideIn type="left">
              <Box
                alt="Trening Ducha i Ciała"
                component="img"
                src={Plakat}
                sx={{
                  width: '100%',
                }}
              />
            </SlideIn>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
          >
            <SlideIn type="right">
              <Stack spacing={3.5}>
                <Typography
                  fontWeight={900}
                  textAlign={isMD ? 'center' : 'left'}
                  variant="h3"
                >
                  Nowość!
                </Typography>
                <Typography
                  fontWeight={900}
                  textAlign={isMD ? 'center' : 'left'}
                  variant="h2"
                >
                  O doświadczeniu mistycznym i obecności Maryi - bp Andrzej
                  Siemieniewski
                </Typography>
                <Typography
                  textAlign={isMD ? 'center' : 'left'}
                  variant="subtitle2"
                >
                  Jak w obecnym świecie odnaleźć prawdziwą głębię duchowości?
                  Jak rozumieć mistykę i wejść w jej doświadczenie? Czy mistyka
                  jest uniwersalna dla wszystkich tradycji religijnych,
                  stanowiąc swoisty język łączący ludzkość? Czy mistyczne
                  doświadczenie Boga jest dostępne dla każdego z nas?
                </Typography>
                <Box
                  display="flex"
                  justifyContent={isMD ? 'center' : 'start'}
                >
                  <Button
                    href="https://sumuswydawnictwo.pl/pl/p/O-doswiadczeniu-mistycznym-i-obecnosci-Maryi-bp-Andrzej-Siemieniewski/1254"
                    rel="noopener noreferrer"
                    size="large"
                    target="_blank"
                    variant="outlined"
                  >
                    Zobacz szczegóły
                  </Button>
                </Box>
              </Stack>
            </SlideIn>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default BookAdvert
