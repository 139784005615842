import { REGIONS } from 'consts/regions'
import { Group } from 'types/group'

const groups: Group[] = [
  {
    id: 'wroclaw',
    name: 'Wrocław',
    email: 'wojownicymaryi.wroclaw@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_WROCLAWSKA,
  },
  {
    id: 'swidnica-walbrzych',
    name: 'Świdnica / Wałbrzych',
    email: 'wojownicymaryi.swidnica@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_WROCLAWSKA,
  },
  {
    id: 'olawa',
    name: 'Oława',
    email: 'wojownicymaryi.olawa@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_WROCLAWSKA,
  },
  {
    id: 'legnica',
    name: 'Legnica',
    email: 'wojownicymaryi.legnica@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_WROCLAWSKA,
  },
  {
    id: 'klodzko',
    name: 'Kłodzko',
    email: 'wojownicymaryi.klodzko@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_WROCLAWSKA,
  },
  {
    id: 'jelenia-gora-cieplice',
    name: 'Jelenia Góra / Cieplice',
    email: 'wojownicymaryi.jeleniagora@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_WROCLAWSKA,
  },
  {
    id: 'boleslawiec',
    name: 'Bolesławiec',
    email: 'wojownicymaryi.boleslawiec@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_WROCLAWSKA,
  },
]

export default groups
