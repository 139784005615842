import { REGIONS } from 'consts/regions'
import { Group } from 'types/group'

const groups: Group[] = [
  {
    id: 'belchatow',
    name: 'Bełchatów',
    email: 'wojownicymaryi.belchatow@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_LODZKA,
  },
  {
    id: 'lodz',
    name: 'Łódź',
    email: 'wojownicymaryi.lodz@gmail.com',
    website: 'https://wojownicylodz.pl/',
    image: '',
    regionId: REGIONS.METROPOLIA_LODZKA,
  },
  {
    id: 'skierniewice',
    name: 'Skierniewice',
    email: 'wojownicymaryi.skierniewice@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_LODZKA,
  },
  {
    id: 'tomaszow-mazowiecki',
    name: 'Tomaszów Mazowiecki',
    email: 'wojownicymaryi.tomaszow@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_LODZKA,
  },
]

export default groups
