import { REGIONS } from 'consts/regions'
import { Group } from 'types/group'

const groups: Group[] = [
  {
    id: 'czestochowa',
    name: 'Częstochowa',
    email: 'wojownicymaryi.czestochowa@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_CZESTOCHOWSKA,
  },
  {
    id: 'dabrowa-gornicza',
    name: 'Dąbrowa Górnicza',
    email: 'wojownicymaryi.dabrowagornicza@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_CZESTOCHOWSKA,
  },
  {
    id: 'pionki',
    name: 'Pionki',
    email: 'wojownicymaryi.pionki@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_CZESTOCHOWSKA,
  },
  {
    id: 'przedborz',
    name: 'Przedbórz',
    email: 'wojownicymaryi.przedborz1@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_CZESTOCHOWSKA,
  },
  {
    id: 'radomsko',
    name: 'Radomsko',
    email: 'wojownicymaryi.radomsko@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_CZESTOCHOWSKA,
  },
  {
    id: 'skarzysko-kamienna',
    name: 'Skarżysko Kamienna',
    email: 'wojownicymaryi.skarzysko@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_CZESTOCHOWSKA,
  },
  {
    id: 'sosnowiec',
    name: 'Sosnowiec',
    email: 'wojownicymaryisosnowiec@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_CZESTOCHOWSKA,
  },
  {
    id: 'wielun',
    name: 'Wieluń',
    email: 'wojownicymaryi.wielun@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_CZESTOCHOWSKA,
  },
  {
    id: 'wolbrom',
    name: 'Wolbrom',
    email: 'wojownicymaryi.wolbrom@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_CZESTOCHOWSKA,
  },
]

export default groups
