import { Event } from 'types/event'

import Bydgoszcz from './images/bydgoszcz.jpeg'
import Gdansk from './images/gdansk.jpeg'
import Gniezno from './images/gniezno.jpeg'
import NabozenstwoImage from './images/nabozenstwo.jpeg'
import NocWalki2 from './images/noc-walki-2.jpeg'
import NocWalki from './images/noc-walki.jpeg'
import Strachocina from './images/strachocina.jpeg'
import WMUrsynow from './images/wm-ursynow.jpeg'
import Wroclaw from './images/wroclaw.jpeg'

const events: Event[] = [
  {
    id: 'nabozenstwo-z-modlitwa-o-uzdrowienie-duszy-i-ciala',
    name: 'Nabożeństwo z modlitwą o uzdrowienie duszy i ciała',
    attention: 'Zapraszamy',
    location: 'Parafia Św. Rodziny w Słupsku',
    startDate: '06-02-2024',
    startTime: '18:30',
    description: 'Szczegóły na plakacie.',
    shortDescription: 'Szczegóły na plakacie',
    image: NabozenstwoImage,
  },
  {
    id: 'noc-walki-o-blogoslawienstwo-dla-polski',
    name: 'Noc Walki o Błogosławieństwo dla Polski',
    attention: 'Dołącz do nas!',
    location: 'Niepokalanów',
    startDate: '16-02-2024',
    startTime: '21:00',
    description: 'Szczegóły na plakacie.',
    shortDescription: 'Szczegóły na plakacie',
    image: NocWalki,
  },
  {
    id: 'noc-walki-o-blogoslawienstwo-dla-polski-warszawa-ursynow',
    name: 'Noc Walki o Błogosławieństwo dla Polski',
    attention: 'Dołącz do nas!',
    location: 'Parafia Ofiarowania Pańskiego ul. Stryjeńskich 21',
    startDate: '16-02-2024',
    startTime: '21:00',
    description: 'Szczegóły na plakacie.',
    shortDescription: 'Szczegóły na plakacie',
    image: NocWalki2,
  },
  {
    id: 'spotkanie-regionalne-warszawa-ursynow',
    name: 'Spotkanie Regionalne - Wojownicy Maryi Ursynów',
    attention: 'Dołącz do nas!',
    location: 'Kościół św. Tomasza Apostoła, ul. Dereniowa 12, Warszawa',
    startDate: '07-02-2024',
    startTime: '18:30',
    description: 'Szczegóły na plakacie.',
    shortDescription: 'Szczegóły na plakacie',
    image: WMUrsynow,
  },
  {
    id: 'noc-walki-o-blogoslawienstwo-dla-polski-gniezno-wrzesnia',
    name: 'Noc Walki o Błogosławieństwo dla Polski (Gniezno / Września)',
    attention: 'Dołącz do nas!',
    location:
      'Parafia pw. Bł. Michała Kozala Biskupa i Męczennika ul. Elizy Orzeszkowej 22d, 62-200 Gniezno',
    startDate: '16-02-2024',
    startTime: '21:00',
    description: 'Szczegóły na plakacie.',
    shortDescription: 'Szczegóły na plakacie',
    image: Gniezno,
  },
  {
    id: 'noc-walki-o-blogoslawienstwo-dla-polski-gdansk',
    name: 'Noc Walki o Błogosławieństwo dla Polski (Gdańsk)',
    attention: 'Dołącz do nas!',
    location: 'Parafia Niepokalanego Poczęcia NMP, Gdańsk, ul. Łąkowa 34a',
    startDate: '16-02-2024',
    startTime: '19:00',
    description: 'Szczegóły na plakacie.',
    shortDescription: 'Szczegóły na plakacie',
    image: Gdansk,
  },
  {
    id: 'noc-walki-o-blogoslawienstwo-dla-polski-bydgoszcz',
    name: 'Noc Walki o Błogosławieństwo dla Polski (Bydgoszcz)',
    attention: 'Dołącz do nas!',
    location:
      'Kościół pw. Św. Marka w Fordonie, ul. Salezjańska 1, 85-792, Bydgoszcz',
    startDate: '16-02-2024',
    startTime: '21:00',
    description: 'Szczegóły na plakacie.',
    shortDescription: 'Szczegóły na plakacie',
    image: Bydgoszcz,
  },
  {
    id: 'noc-walki-o-blogoslawienstwo-dla-polski-wroclaw',
    name: 'Noc Walki o Błogosławieństwo dla Polski (Wrocław)',
    attention: 'Dołącz do nas!',
    location: 'Sanktuarium Matki Bożej Miłosierdzia, ul. Ołbińska 1, Wrocław',
    startDate: '16-02-2024',
    startTime: '21:00',
    description: 'Szczegóły na plakacie.',
    shortDescription: 'Szczegóły na plakacie',
    image: Wroclaw,
  },
  {
    id: 'noc-walki-o-blogoslawienstwo-dla-polski-strachocina',
    name: 'Noc Walki o Błogosławieństwo dla Polski (Strachocina)',
    attention: 'Dołącz do nas!',
    location: 'Sanktuarium Świętego Andrzeja Boboli w Strachocinie',
    startDate: '16-02-2024',
    startTime: '17:00',
    description: 'Szczegóły na plakacie.',
    shortDescription: 'Szczegóły na plakacie',
    image: Strachocina,
  },
]

export default events
