import { IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Box, Container } from '@mui/system'
import { FaFacebookF, FaYoutube } from 'react-icons/fa'
import { MdAlternateEmail } from 'react-icons/md'
import { Link } from 'react-router-dom'

import WMLogo from 'images/wmlogo.png'

const Footer = () => {
  const theme = useTheme()

  const isSM = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Container
      maxWidth="xl"
      sx={{ marginTop: 'auto' }}
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection={isSM ? 'column' : 'row'}
        justifyContent="space-between"
        gap={isSM ? 2 : 0}
        py={3}
      >
        <Typography
          fontSize="1.2rem"
          variant="subtitle2"
        >
          Wojownicy Maryi @ {new Date().getUTCFullYear()}
        </Typography>
        <Link
          style={{ maxHeight: 100, marginRight: !isSM ? theme.spacing(5) : 0 }}
          to="/"
        >
          <Box
            alt="Wojownicy Maryi Logo"
            component="img"
            maxWidth={100}
            src={WMLogo}
          />
        </Link>
        <Box>
          <IconButton
            href="https://www.youtube.com/user/juffik10"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FaYoutube />
          </IconButton>
          <IconButton href="mailto:wojownicymaryi.info@gmail.com">
            <MdAlternateEmail />
          </IconButton>
          <IconButton
            href="https://www.facebook.com/WMaryi"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FaFacebookF />
          </IconButton>
        </Box>
      </Box>
    </Container>
  )
}

export default Footer
