import { REGIONS } from 'consts/regions'
import { Group } from 'types/group'

const groups: Group[] = [
  {
    id: 'garwolin',
    name: 'Garwolin',
    email: 'wojownicymaryi.garwolin@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_LUBELSKA,
  },
  {
    id: 'janow-lubelski',
    name: 'Janów Lubelski',
    email: 'wojownicymaryi.janowlubelski@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_LUBELSKA,
  },
  {
    id: 'jezowe',
    name: 'Jeżowe',
    email: 'wojownicymaryi.jezowe@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_LUBELSKA,
  },
  {
    id: 'lublin',
    name: 'Lublin',
    email: 'wojownicymaryi.lublin@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_LUBELSKA,
  },
  {
    id: 'leczna',
    name: 'Łęczna',
    email: 'wojownicymaryi.leczna@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_LUBELSKA,
  },
  {
    id: 'lukow',
    name: 'Łuków',
    email: 'wojownicymaryi.lukow@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_LUBELSKA,
  },
  {
    id: 'miedzyrzec-podlaski',
    name: 'Międzyrzec Podlaski',
    email: 'wojownicymaryi.miedzyrzec.podl@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_LUBELSKA,
  },
  {
    id: 'ostrowiec-swietokrzyski',
    name: 'Ostrowiec Świętokrzyski',
    email: 'zbigniewkrycia1971@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_LUBELSKA,
  },
  {
    id: 'pulawy',
    name: 'Puławy',
    email: 'wojownicymaryi.pulawy@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_LUBELSKA,
  },
  {
    id: 'siedlce',
    name: 'Siedlce',
    email: 'wojownicymaryi.siedlce@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_LUBELSKA,
  },
  {
    id: 'stalowa-wola',
    name: 'Stalowa Wola',
    email: 'wojownicymaryi.stalowawola@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_LUBELSKA,
  },
  {
    id: 'wlodawa',
    name: 'Włodawa',
    email: 'wojownicymaryi.wlodawa@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_LUBELSKA,
  },
]

export default groups
