import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'

import Relation from 'components/Relation'

import g1 from './images/g1.jpg'
import g2 from './images/g2.jpg'
import g3 from './images/g3.jpg'
import g4 from './images/g4.jpg'
import g5 from './images/g5.jpg'
import g6 from './images/g6.jpg'

const images = [g6, g5, g3, g4, g2, g1]

const RegionalneBanner = () => {
  const theme = useTheme()

  const isMD = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Relation
      attention="Dołacz do grupy regionalnej!"
      background={theme.palette.primary.main}
      images={images}
      reversed
    >
      <Typography
        color="white"
        fontWeight={900}
        textAlign={isMD ? 'center' : 'left'}
        variant="h2"
      >
        Spotkania Regionalne
      </Typography>
      <Typography
        color="white"
        textAlign={isMD ? 'center' : 'left'}
        variant="body2"
      >
        Oprócz spotkań ogólnopolskich, odbywają się też regionalne spotkania
        organizowane przez <b>Wojowników Maryi</b>.
      </Typography>
      <Typography
        color="white"
        textAlign={isMD ? 'center' : 'left'}
        variant="body2"
      >
        Są one utrzymane w charakterystyce i charyzmacie wspólnoty i są
        zakładane i prowadzone przez wcześniej przygotowanych liderów, którymi
        są członkowie wspólnoty - którzy przeszli formacje, są po Pasowaniu oraz
        dodatkowo zostali do tej funkcji przygotowani.
      </Typography>
      <Typography
        color="white"
        textAlign={isMD ? 'center' : 'left'}
        variant="body2"
      >
        <b>
          Na spotkania organizowane przez Wojowników Maryi, zapraszamy mężczyzn
          w wieku 18-50 lat.
        </b>
      </Typography>
      <Box
        display="flex"
        justifyContent={isMD ? 'center' : 'start'}
      >
        <Button
          color="secondary"
          href="/grupy"
          size="large"
          variant="outlined"
        >
          Grupy regionalne
        </Button>
      </Box>
    </Relation>
  )
}

export default RegionalneBanner
