import { REGIONS } from 'consts/regions'
import { Group } from 'types/group'

const groups: Group[] = [
  {
    id: 'bydgoszcz-matki-bozej-fatimskiej',
    name: 'Bydgoszcz Matki Bożej Fatimskiej',
    email: 'wojownicymaryi.bydgoszcz@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_GNIEZNIENSKA,
  },
  {
    id: 'bydgoszcz-fordon',
    name: 'Bydgoszcz Fordon',
    email: 'wojownicymaryi.bydgoszcz.fordon@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_GNIEZNIENSKA,
  },
  {
    id: 'bydgoszcz-bazylika',
    name: 'Bydgoszcz Bazylika',
    email: 'wojownicymaryi.bdgbazylika@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_GNIEZNIENSKA,
  },
  {
    id: 'inowroclaw',
    name: 'Inowrocław',
    email: 'wojownicymaryi.inowroclaw@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_GNIEZNIENSKA,
  },
  {
    id: 'konin',
    name: 'Konin',
    email: 'wojownicymaryi.konin@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_GNIEZNIENSKA,
  },
  {
    id: 'wagrowiec',
    name: 'Wągrowiec',
    email: 'wojownicymaryi.wagrowiec@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_GNIEZNIENSKA,
  },
  {
    id: 'wloclawek',
    name: 'Włocławek',
    email: 'wojownicymaryi.wloclawek@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_GNIEZNIENSKA,
  },
  {
    id: 'wrzesnia',
    name: 'Września',
    email: 'wojownicymaryi.wrzesnia@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_GNIEZNIENSKA,
  },
  {
    id: 'znin',
    name: 'Żnin ',
    email: 'wojownicymaryiznin@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_GNIEZNIENSKA,
  },
]

export default groups
