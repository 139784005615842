import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Container, alpha } from '@mui/system'
import { motion } from 'framer-motion'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import LightGallery from 'lightgallery/react'
import type { ReactNode } from 'react'

interface Props {
  attention: string
  background?: string
  children?: ReactNode
  images: string[]
  reversed?: boolean
  title?: string
}

const Relation = ({
  attention,
  background,
  children,
  images,
  reversed = false,
  title,
}: Props) => {
  const theme = useTheme()

  const isMD = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Container
      maxWidth={false}
      sx={{
        background: background || theme.palette.common.white,
      }}
    >
      <Container maxWidth="xl">
        <Box py={15}>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              xs={12}
              lg={6}
              order={{
                xs: reversed ? 2 : 1,
              }}
            >
              <motion.div
                initial={{
                  x: isMD ? 0 : reversed ? 100 : -100,
                  y: isMD ? (reversed ? 100 : -100) : 0,
                }}
                whileInView={{
                  x: 0,
                  y: 0,
                }}
                transition={{
                  duration: 0.5,
                }}
              >
                <Stack spacing={2}>
                  <Typography
                    fontWeight={900}
                    textAlign={isMD ? 'center' : 'left'}
                    variant="h4"
                  >
                    {attention}
                  </Typography>
                  {title && (
                    <Typography
                      fontWeight={900}
                      textAlign={isMD ? 'center' : 'left'}
                      variant="h2"
                    >
                      {title}
                    </Typography>
                  )}
                  {children}
                </Stack>
              </motion.div>
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              order={{
                xs: 2,
                lg: reversed ? 1 : 2,
              }}
              textAlign="center"
            >
              <motion.div
                initial={{
                  x: isMD ? 0 : reversed ? -100 : 100,
                  y: isMD ? (reversed ? -100 : 100) : 0,
                }}
                whileInView={{
                  x: 0,
                  y: 0,
                }}
                transition={{
                  duration: 0.5,
                }}
              >
                <LightGallery
                  thumbnail
                  speed={500}
                  plugins={[lgThumbnail, lgZoom]}
                >
                  {images.map((image, index) => (
                    <a
                      href={image}
                      key={index}
                    >
                      <Box
                        component="img"
                        src={image}
                        sx={{
                          boxShadow: `${alpha(
                            theme.palette.common.black,
                            0.35
                          )} 0px 5px 15px`,
                          maxHeight: 200,
                          [theme.breakpoints.down('lg')]: {
                            maxHeight: 150,
                          },
                          outline: `4px solid ${theme.palette.common.white}`,
                        }}
                      />
                    </a>
                  ))}
                </LightGallery>
              </motion.div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Container>
  )
}

export default Relation
