import { Event } from 'types/event'

import NocnaAdoracja from './images/nocna-adoracja.jpg'
import Ogolnopolskie from './images/ogolnopolskie.jpg'

const events: Event[] = [
  {
    id: 'ojciec-i-syn-spotkanie-ogolnopolskie',
    name: 'Ojciec i Syn - Spotkanie Ogólnopolskie',
    attention: 'Zapraszamy',
    location: 'Parafia Najświętszego Serca Pana Jezusa, Rzeszów',
    startDate: '20-04-2024',
    startTime: '09:30',
    description: 'Szczegóły na plakacie.',
    shortDescription: 'Szczegóły na plakacie',
    image: Ogolnopolskie,
  },
  {
    id: 'nocna-adoracja-pokutno-blagalna',
    name: 'Nocna Adoracja Pokutno - Błagalna',
    attention: 'Dołącz do nas!',
    location:
      'Parafia Bogurodzicy Maryi, ul. Powstańców Śląskich 67D, 01-355 Warszawa',
    startDate: '10-04-2024',
    startTime: '23:00',
    description: 'Szczegóły na plakacie.',
    shortDescription: 'Szczegóły na plakacie',
    image: NocnaAdoracja,
  },
]

export default events
