import { Box } from '@mui/material'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Route, Routes, useLocation } from 'react-router-dom'

import Footer from 'components/Footer'
import Navigation from 'components/Navigation'
import AktualnosciPage from 'pages/AktualnosciPage'
import EventPage from 'pages/EventPage'
import EventsPage from 'pages/EventsPage'
import GroupsPage from 'pages/GroupsPage'
import HomePage from 'pages/HomePage'
import KodexPage from 'pages/KodexPage'

const App = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [location.pathname])

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
    >
      <Helmet>
        <title>Wojownicy Maryi</title>
        <meta
          name="description"
          content="Wojownicy Maryi"
        />
      </Helmet>
      <Navigation />
      <Routes
        key={location.pathname}
        location={location}
      >
        <Route
          element={<HomePage />}
          path="/"
        />
        <Route
          element={<EventsPage />}
          path="/wydarzenia"
        />
        <Route
          element={<EventPage />}
          path="/wydarzenia/:id"
        />
        <Route
          element={<KodexPage />}
          path="/kodex"
        />
        <Route
          element={<GroupsPage />}
          path="/grupy"
        />
        <Route
          element={<AktualnosciPage />}
          path="/aktualnosci"
        />
      </Routes>
      <Footer />
    </Box>
  )
}

export default App
