import { REGIONS } from 'consts/regions'
import { Group } from 'types/group'

const groups: Group[] = [
  {
    id: 'antwerpia',
    name: 'Antwerpia (Belgia)',
    email: 'wojownicymaryi.antwerpia@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.GRUPY_ZAGRANICZNE,
  },
  {
    id: 'brampton',
    name: 'Brampton (Toronto)',
    email: 'wojownicymaryi.brampton@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.GRUPY_ZAGRANICZNE,
  },
  {
    id: 'bridgeport-ct',
    name: 'Bridgeport CT',
    email: 'wojownicymaryinewbritain@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.GRUPY_ZAGRANICZNE,
  },
  {
    id: 'lombard',
    name: 'Lombard (Chicago)',
    email: 'wojownicymaryi.lombard@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.GRUPY_ZAGRANICZNE,
  },
  {
    id: 'derby',
    name: 'Derby (UK)',
    email: 'wojownicymaryiderby@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.GRUPY_ZAGRANICZNE,
  },
  {
    id: 'hamburg',
    name: 'Hamburg',
    email: 'wojownicymaryi.hamburg@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.GRUPY_ZAGRANICZNE,
  },
  {
    id: 'namur-belgia',
    name: 'Namur (Belgia)',
    email: 'wojownicymaryi.namur@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.GRUPY_ZAGRANICZNE,
  },
  {
    id: 'navan-irlandia',
    name: 'Navan (Irlandia)',
    email: 'wojownicymaryi.navan@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.GRUPY_ZAGRANICZNE,
  },
  {
    id: 'new-britain-usa',
    name: 'New Britain (USA)',
    email: 'wojownicymaryi.newbritain@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.GRUPY_ZAGRANICZNE,
  },
  {
    id: 'kopenhaga',
    name: 'Kopenhaga',
    email: 'wojownicymaryi.kopenhaga@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.GRUPY_ZAGRANICZNE,
  },
  {
    id: 'londyn',
    name: 'Londyn (UK)',
    email: 'wojownicymaryi.london@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.GRUPY_ZAGRANICZNE,
  },
  {
    id: 'paryz-francja',
    name: 'Paryż (Francja)',
    email: 'wojownicymaryi.paryz@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.GRUPY_ZAGRANICZNE,
  },
  {
    id: 'reading-uk',
    name: 'Reading (UK)',
    email: 'wojownicymaryi.reading@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.GRUPY_ZAGRANICZNE,
  },
  {
    id: 'split-chorwacja',
    name: 'Split (Chorwacja)',
    email: 'wojownicymaryi.split@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.GRUPY_ZAGRANICZNE,
  },
  {
    id: 'sztokholm-szwecja',
    name: 'Sztokholm (Szwecja)',
    email: 'wojownicymaryi.sztokholm@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.GRUPY_ZAGRANICZNE,
  },
  {
    id: 'trewir-niemcy',
    name: 'Trewir (Niemcy)',
    email: 'wojownicymaryi.trewir@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.GRUPY_ZAGRANICZNE,
  },
  {
    id: 'wieden-austria',
    name: 'Wiedeń (Austria)',
    email: 'wojownicymaryi.wieden@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.GRUPY_ZAGRANICZNE,
  },
  {
    id: 'zurych-szwajcaria',
    name: 'Zurych (Szwajcaria)',
    email: 'wojownicymaryi.zurych@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.GRUPY_ZAGRANICZNE,
  },
]

export default groups
