import { Event } from 'types/event'

import DlaBezsilnych from './images/dla-bezsilnych.jpg'
import MekaPanska from './images/meka-panska.jpeg'
import Metropolitalne from './images/metropolitalne.jpeg'
import SwJozefZawierzenie from './images/sw-jozef-zawierzenie.jpg'
import Wieczernik from './images/wieczernik.jpeg'
import WMUrsynow from './images/wm-ursynow-regionalne.jpg'
import ZaZyciem from './images/za-zyciem.jpeg'

const events: Event[] = [
  {
    id: 'wieczernik-plomienia-milosci-niepokalanego-serca-maryi',
    name: 'Wieczernik Płomienia Miłości Niepokalanego Serca Maryi',
    attention: 'Zapraszamy',
    location: 'Dom Pielgrzyma, Góra św. Anny',
    startDate: '01-03-2024',
    startTime: '10:00',
    description: 'Szczegóły na plakacie.',
    shortDescription: 'Szczegóły na plakacie',
    image: Wieczernik,
  },
  {
    id: '24-h-meka-panska-plonsk',
    name: '24H Męka Pańska z rozważaniami według Luizy Piccaretty',
    attention: 'Wynagradzaj z nami!',
    location:
      'Parafia św. Maksymiliana Kolbego w Płoński, ul. Księdza Romualda Jaworskiego 1',
    startDate: '15-03-2024',
    startTime: '18:00',
    description: 'Szczegóły na plakacie',
    shortDescription: 'Szczegóły na plakacie',
    image: MekaPanska,
  },
  {
    id: 'otwarte-spotkanie-rodzinne-legionowo',
    name: 'Wojownicy Maryi - metropolitalne otwarte spotkanie rodzinne - Legionowo',
    attention: 'Zapraszamy!',
    location: 'Arena Legionowo',
    startDate: '17-03-2024',
    startTime: '09:30',
    description: 'Szczegóły na plakacie',
    shortDescription: 'Szczegóły na plakacie',
    image: Metropolitalne,
  },
  {
    id: 'spotkanie-regionalne-warszawa-ursynow',
    name: 'Spotkanie Regionalne - Wojownicy Maryi Ursynów',
    attention: 'Dołącz do nas!',
    location: 'Kościół św. Tomasza Apostoła, ul. Dereniowa 12, Warszawa',
    startDate: '06-03-2024',
    startTime: '18:30',
    description: 'Szczegóły na plakacie.',
    shortDescription: 'Szczegóły na plakacie',
    image: WMUrsynow,
  },
  {
    id: 'rekolekcje-dla-bezsilnych-edycja-2',
    name: 'Rekolekcje dla bezsilnych (Edycja 2)',
    attention: 'Dołącz do nas!',
    location: 'Niepokalanów',
    startDate: '22-03-2024',
    startTime: '17:00',
    description: 'Szczegóły na plakacie.',
    shortDescription: 'Szczegóły na plakacie',
    image: DlaBezsilnych,
  },
  {
    id: 'meska-droga-krzyzowa-za-zyciem',
    name: 'Męska Droga Krzyżowa - ZA ŻYCIEM',
    attention: 'Zapraszamy!',
    location: 'Kościół Św. Anny na Krakowskim Przedmieściu, Warszawa',
    startDate: '15-03-2024',
    startTime: '17:00',
    description: 'Szczegóły na plakacie.',
    shortDescription: 'Szczegóły na plakacie',
    image: ZaZyciem,
  },
  {
    id: 'warszawa-siekierki-spotkanie-regionalne',
    name: 'Św. Józef - Zawierzenie - Spotkanie Regionalne (WM Siekierki)',
    attention: 'Zapraszamy!',
    location:
      'Sanktuarium Matki Bożej Nauczycielki Młodzieży, Gwintowa 3, Warszawa',
    startDate: '19-03-2024',
    startTime: '19:00',
    description: 'Szczegóły na plakacie.',
    shortDescription: 'Szczegóły na plakacie',
    image: SwJozefZawierzenie,
  },
]

export default events
