import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import { Container, Stack, alpha, useTheme } from '@mui/system'

import { SlideIn } from 'components/animations'
import DominikChmielewski from 'images/ks-dominik-chmielewski.jpg'

const OpiekunBanner = () => {
  const theme = useTheme()

  const isSM = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Container
      maxWidth={false}
      sx={{
        background: `linear-gradient(to right, ${alpha(
          theme.palette.common.black,
          0.7
        )}, ${alpha(theme.palette.common.black, 0)}, ${alpha(
          theme.palette.common.black,
          0.7
        )}), url(${DominikChmielewski})`,
        backgroundAttachment: isSM ? 'initial' : 'fixed',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
        }}
      >
        <SlideIn type="left">
          <Stack
            my={25}
            spacing={3.5}
          >
            <Typography
              fontWeight={900}
              textAlign={isSM ? 'center' : 'left'}
              variant="h3"
            >
              Założyciel ruchu
            </Typography>
            <Typography
              color="white"
              fontWeight={900}
              textAlign={isSM ? 'center' : 'left'}
              variant="h2"
            >
              ks. Dominik Chmielewski SDB
            </Typography>
            <Typography
              color="white"
              maxWidth="sm"
              textAlign={isSM ? 'center' : 'left'}
              variant="subtitle2"
            >
              Założycielem ruchu jest znany i ceniony rekolekcjonista,
              spowiednik, autor książek - ks. Dominik Chmielewski! Jest
              salezjaninem i obecnie przebywa w klasztorze salezjańskim w
              Lądzie.
            </Typography>
            <Box
              display="flex"
              justifyContent={isSM ? 'center' : 'start'}
            >
              <Button
                color="secondary"
                href="https://dominikchmielewski.pl/"
                rel="noopener noreferrer"
                size="large"
                target="_blank"
                variant="outlined"
              >
                Dowiedz się więcej
              </Button>
            </Box>
          </Stack>
        </SlideIn>
      </Container>
    </Container>
  )
}

export default OpiekunBanner
