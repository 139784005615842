import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import InfoIcon from '@mui/icons-material/Info'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { useToggle } from 'react-use'

import type { Event as EventType } from 'types/event'

interface Props {
  event: EventType
}

const Event = ({ event }: Props) => {
  const theme = useTheme()

  const isVertical = useMediaQuery(theme.breakpoints.down('lg'))
  const isSM = useMediaQuery(theme.breakpoints.down('sm'))

  const [showCopiedToast, toggleCopiedToast] = useToggle(false)

  if (isSM) {
    return (
      <Link to={`/wydarzenia/${event.id}`}>
        <CardMedia
          component="img"
          image={event.image}
          sx={{
            borderRadius: 3,
            boxShadow: 10,
            maxWidth: '100%',
          }}
        />
      </Link>
    )
  }

  return (
    <Card elevation={0}>
      <Box
        display="flex"
        flexDirection={isVertical ? 'column' : 'row'}
        gap={5}
      >
        <CardMedia
          component="img"
          image={event.image}
          sx={{
            borderRadius: 3,
            boxShadow: 10,
            maxWidth: isVertical ? '100%' : 400,
          }}
        />
        <Box>
          <CardContent sx={{ height: '100%' }}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              gap={2}
              height="100%"
            >
              {!isSM && (
                <Typography
                  fontSize={30}
                  fontWeight={900}
                  textAlign={isSM ? 'center' : 'left'}
                  variant="h4"
                >
                  {event.attention}
                </Typography>
              )}
              <Typography
                fontFamily="Oswald"
                fontSize={48}
                fontWeight={900}
                textAlign={isSM ? 'center' : 'left'}
                variant="h3"
              >
                {event.name}
              </Typography>
              <Typography
                textAlign={isSM ? 'center' : 'left'}
                variant="body2"
              >
                {event.shortDescription}
              </Typography>
              {!isSM && (
                <>
                  <Box
                    alignItems="center"
                    display="flex"
                    gap={1}
                  >
                    <LocationOnIcon fontSize="large" />
                    <Typography
                      fontSize={18}
                      fontWeight={500}
                      variant="subtitle2"
                    >
                      {event.location}
                    </Typography>
                  </Box>
                  <Box
                    alignItems="center"
                    display="flex"
                    gap={1}
                  >
                    <CalendarMonthIcon fontSize="large" />
                    <Typography
                      fontSize={18}
                      fontWeight={500}
                      variant="subtitle2"
                    >
                      {event.startDate}
                    </Typography>
                  </Box>
                  <Box
                    alignItems="center"
                    display="flex"
                    gap={1}
                  >
                    <AccessTimeIcon fontSize="large" />
                    <Typography
                      fontSize={18}
                      variant="subtitle2"
                    >
                      {event.startTime}
                    </Typography>
                  </Box>
                </>
              )}
              <Link to={`/wydarzenia/${event.id}`}>
                <Button
                  endIcon={<InfoIcon />}
                  fullWidth={isVertical}
                  size="large"
                  variant="outlined"
                >
                  Szczegóły
                </Button>
              </Link>
            </Box>
          </CardContent>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={5000}
        onClose={toggleCopiedToast}
        open={showCopiedToast}
        style={{
          top: 60,
        }}
      >
        <Alert severity="info">
          Link do wydarzenia został skopiowany do schowka
        </Alert>
      </Snackbar>
    </Card>
  )
}

export default Event
