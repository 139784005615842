import { Box, Button, Container, Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { motion } from 'framer-motion'
import take from 'lodash/take'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import events from 'data/events'

const UpcomingEvent = () => {
  const visibleEvents = useMemo(() => {
    return events.filter((event) =>
      dayjs(event.startDate, 'DD-MM-YYYY').isAfter(dayjs().subtract(1, 'day'))
    )
  }, [])

  if (!visibleEvents || visibleEvents.length === 0) {
    return null
  }

  const eventsToShow = 6

  return (
    <Container maxWidth="xl">
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        gap={5}
        justifyContent="center"
        py={15}
      >
        <Typography
          fontWeight={900}
          textAlign="center"
          variant="h2"
        >
          Nadchodzące wydarzenia
        </Typography>
        <Typography
          maxWidth="lg"
          textAlign="center"
          variant="subtitle2"
        >
          Serdecznie zapraszamy do wzięcia udziału w nadchodzących wydarzeniach
          organizowanych lub współorganizowanych przez wspólnotę Wojowników
          Maryi!
        </Typography>
        <Grid
          container
          justifyContent="center"
          spacing={3}
        >
          {take(visibleEvents, eventsToShow).map((event) => (
            <Grid
              key={event.id}
              item
              sm={12}
              md={4}
              justifyContent="center"
              textAlign="center"
            >
              <motion.div
                initial={{
                  opacity: 0.8,
                  scale: 0.9,
                }}
                whileInView={{
                  opacity: 1,
                  scale: 1,
                }}
                transition={{
                  duration: 0.5,
                }}
              >
                <Link to={`/wydarzenia/${event.id}`}>
                  <Box
                    component="img"
                    src={event.image}
                    sx={{
                      borderRadius: 2,
                      boxShadow:
                        'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
                      maxWidth: '100%',
                      transition: 'transform .3s',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                    }}
                  />
                </Link>
              </motion.div>
            </Grid>
          ))}
        </Grid>
        <Box mt={1}>
          <Link to="/wydarzenia">
            <Button
              size="large"
              variant="outlined"
            >
              Wydarzenia
            </Button>
          </Link>
        </Box>
      </Box>
    </Container>
  )
}

export default UpcomingEvent
