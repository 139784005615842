import { REGIONS } from 'consts/regions'
import { Group } from 'types/group'

const groups: Group[] = [
  {
    id: 'andrychow',
    name: 'Andrychów',
    email: 'wojownicymaryi.andrychow@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_KRAKOWSKA,
  },
  {
    id: 'brzesko',
    name: 'Brzesko',
    email: 'wojownicymaryi.brzesko@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_KRAKOWSKA,
  },
  {
    id: 'debica',
    name: 'Dębica',
    email: 'wojownicymaryi.debica@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_KRAKOWSKA,
  },
  {
    id: 'harmeze-oswiecim',
    name: 'Harmęże / Oświęcim',
    email: 'wojownicymaryi.harmeze.oswiecim@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_KRAKOWSKA,
  },
  {
    id: 'kielce',
    name: 'Kielce',
    email: 'wojownicymaryi.kielce@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_KRAKOWSKA,
  },
  {
    id: 'krakow-centrum',
    name: 'Kraków Centrum',
    email: 'wojownicymaryi.krakow@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_KRAKOWSKA,
  },
  {
    id: 'nowy-sacz',
    name: 'Nowy Sącz',
    email: 'wojownicymaryi.nowysacz@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_KRAKOWSKA,
  },
  {
    id: 'nowy-targ',
    name: 'Nowy Targ',
    email: 'wojownicymaryi.nowytarg@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_KRAKOWSKA,
  },
  {
    id: 'wieliczka',
    name: 'Wieliczka',
    email: 'wojownicymaryi.wieliczka@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_KRAKOWSKA,
  },
  {
    id: 'wisla',
    name: 'Wisła',
    email: 'wojownicymaryi.wisla@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_KRAKOWSKA,
  },
]

export default groups
