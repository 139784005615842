import {
  Box,
  Grid,
  Icon,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Container, Stack } from '@mui/system'
import { motion } from 'framer-motion'
import type { ReactNode } from 'react'
import { FaChurch, FaPray, FaPrayingHands, FaUserFriends } from 'react-icons/fa'

import { theme } from 'theme'

interface ItemProps {
  icon: ReactNode
  index: number
  text: ReactNode
}

const Item = ({ icon, index, text }: ItemProps) => {
  return (
    <Grid
      item
      sm={6}
      lg={3}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.7 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{
          delay: index * 0.1,
          duration: 0.3,
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          mb={2}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.common.black,
              borderRadius: '50%',
              height: 80,
              padding: theme.spacing(2),
              width: 80,
            }}
          >
            <Icon
              fontSize="large"
              sx={{ color: theme.palette.common.white }}
            >
              {icon}
            </Icon>
          </Box>
        </Box>
        <Typography
          textAlign="center"
          variant="subtitle2"
        >
          {text}
        </Typography>
      </motion.div>
    </Grid>
  )
}

interface Props {
  children?: ReactNode
}

const KimJestWojownikMaryi = ({ children }: Props) => {
  const theme = useTheme()

  const isSM = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Container maxWidth="xl">
      <Stack
        alignItems="center"
        display="flex"
        py={18}
        spacing={8}
      >
        <Typography
          fontWeight={900}
          textAlign={isSM ? 'center' : 'left'}
          variant="h2"
        >
          Kim jest Wojownik Maryi?
        </Typography>
        <Grid
          container
          columnGap={isSM ? 4 : 0}
          rowGap={4}
          spacing={isSM ? 0 : 4}
        >
          <Item
            icon={<FaChurch />}
            index={0}
            text={
              <>
                Wojownikiem Maryi jest mężczyzna, który przeszedł formację wg
                podanych zasad, podczas <b>ogólnopolskich</b> i{' '}
                <b>regionalnych</b> spotkań.
              </>
            }
          />
          <Item
            icon={<FaPrayingHands />}
            index={1}
            text={
              <>
                Odbył przygotowanie uwzględniające wskazówki i zasady podane
                przed lidera regionalnego oraz został dopuszczony do{' '}
                <b>Pasowania</b>.
              </>
            }
          />
          <Item
            icon={<FaPray />}
            index={2}
            text={
              <>
                Odbył <b>rozmowę braterską</b> oraz przystąpił do{' '}
                <b>Pasowania</b> odbywającego się na koniec roku formacyjnego.
              </>
            }
          />
          <Item
            icon={<FaUserFriends />}
            index={3}
            text={
              <>
                Prowadzi aktywną formację w zgodzie z <b>Kodexem</b> i{' '}
                <b>Statutem</b> Wojownika Maryi we wspólnocie z resztą braci.
              </>
            }
          />
        </Grid>
        {children}
      </Stack>
    </Container>
  )
}

export default KimJestWojownikMaryi
