import { Box, Container, Typography, useMediaQuery } from '@mui/material'
import { Helmet } from 'react-helmet'

import Mapa from 'images/mapa.jpg'
import { theme } from 'theme'

const meetings = [
  {
    date: '14.09.2024',
    name: 'Ogólnopolskie Spotkanie Wojowników Maryi',
    location: 'Gdańsk',
  },
  {
    date: '12.10.2024',
    name: 'Ogólnopolskie Spotkanie Wojowników Maryi',
    location: 'Olsztyn',
  },
  {
    date: '11.01.2025',
    name: 'Ogólnopolskie Spotkanie Wojowników Maryi',
    location: '',
  },
  {
    date: '15.02.2025',
    name: 'Ogólnopolskie Spotkanie Wojowników Maryi',
    location: '',
  },
  {
    date: '10.05.2025',
    name: 'Ogólnopolskie Spotkanie Wojowników Maryi',
    location: '',
  },
  {
    date: '21.06.2025',
    name: 'Pasowanie na Wojownika Maryi',
    location: 'Rumia',
  },
]

const AktualnosciPage = () => {
  const isSM = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Helmet>
        <title>Aktualności | Wojownicy Maryi</title>
        <meta
          name="description"
          content="Wojownicy Maryi"
        />
      </Helmet>
      <Container maxWidth="xl">
        <Box py={15}>
          <Typography
            mb={5}
            textAlign="center"
            variant="h3"
          >
            Plan spotkań 2024 / 2025
          </Typography>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            {meetings.map((meeting) => (
              <Box
                alignItems="center"
                display="flex"
                flexDirection={isSM ? 'column' : 'row'}
                key={meeting.date}
                my={1}
              >
                <Typography
                  textAlign={isSM ? 'center' : 'left'}
                  mr={1}
                >
                  {meeting.name}{' '}
                </Typography>
                <Typography textAlign={isSM ? 'center' : 'left'}>
                  (<b>{meeting.date}</b>
                  {meeting.location && ' '}
                  {meeting.location && (
                    <>
                      / <b>{meeting.location}</b>
                    </>
                  )}
                  )
                </Typography>
              </Box>
            ))}
          </Box>

          <Typography
            my={5}
            textAlign="center"
            variant="h3"
          >
            Mapa regionów
          </Typography>
          <Box
            component="img"
            src={Mapa}
            sx={{
              borderRadius: 3,
              boxShadow: 10,
              maxWidth: '100%',
            }}
          />
        </Box>
      </Container>
    </>
  )
}

export default AktualnosciPage
