import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { motion, useWillChange } from 'framer-motion'
import { FaBars, FaFacebookF, FaTimes, FaYoutube } from 'react-icons/fa'
import { MdAlternateEmail } from 'react-icons/md'
import { Link, useLocation } from 'react-router-dom'
import { useToggle } from 'react-use'

import WMLogo from 'images/wmlogo.png'
import WMLogoWhite from 'images/wmlogowhite.svg'

import { navigationLinks } from './consts'

const WHITE_LOGO_PATHNAMES = ['/', '/kodex']

const Navigation = () => {
  const theme = useTheme()

  const willChange = useWillChange()

  const isMD = useMediaQuery(theme.breakpoints.down('md'))

  const location = useLocation()

  const isLogoWhite = WHITE_LOGO_PATHNAMES.some(
    (pathname) => pathname === location.pathname
  )

  const [isOpen, toggleOpen] = useToggle(false)

  const socialLinkStyles = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1.5),
  }

  const socialLinks = (
    <Box
      alignItems="center"
      display="flex"
      gap={1}
    >
      <IconButton
        href="https://www.youtube.com/user/juffik10"
        rel="noopener noreferrer"
        target="_blank"
        sx={socialLinkStyles}
      >
        <FaYoutube size={32} />
      </IconButton>
      <IconButton
        href="mailto:wojownicymaryi.info@gmail.com"
        sx={socialLinkStyles}
      >
        <MdAlternateEmail size={32} />
      </IconButton>
      <IconButton
        href="https://www.facebook.com/WMaryi"
        rel="noopener noreferrer"
        sx={socialLinkStyles}
        target="_blank"
      >
        <FaFacebookF size={32} />
      </IconButton>
    </Box>
  )

  return (
    <>
      <Link
        style={{
          left: 'calc(50vw - 50px)',
          maxHeight: 100,
          position: 'absolute',
          right: 0,
          top: 10,
        }}
        to="/"
      >
        {isLogoWhite ? (
          <Box
            alt="Wojownicy Maryi"
            component="img"
            maxHeight={100}
            src={WMLogoWhite}
          />
        ) : (
          <Box
            alt="Wojownicy Maryi"
            component="img"
            maxHeight={100}
            src={WMLogo}
          />
        )}
      </Link>
      <Box
        component="nav"
        sx={{ height: 0, position: 'relative' }}
      >
        <motion.div
          initial={{
            opacity: 0,
            x: 200,
          }}
          animate={{
            opacity: 1,
            x: 0,
          }}
          transition={{
            duration: 0.5,
          }}
        >
          <Box
            onClick={toggleOpen}
            sx={{
              alignItems: 'center',
              backgroundColor: isOpen
                ? theme.palette.primary.main
                : theme.palette.common.white,
              border: `4px solid ${theme.palette.primary.main}`,
              cursor: 'pointer',
              display: 'flex',
              margin: theme.spacing(2.5),
              padding: theme.spacing(isMD ? 1 : 1),
              position: 'fixed',
              right: 0,
              zIndex: 1000,
            }}
          >
            {isOpen ? (
              <FaTimes
                color="white"
                size={36}
              />
            ) : (
              <FaBars size={36} />
            )}
          </Box>
        </motion.div>
      </Box>
      <motion.div
        animate={{
          x: isOpen ? '0' : '100vw',
        }}
        initial={{
          x: '100vw',
        }}
        transition={{
          ease: 'easeInOut',
          duration: 0.3,
        }}
        style={{
          backgroundColor: theme.palette.common.white,
          boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
          height: '100vh',
          left: 0,
          padding: theme.spacing(3),
          position: 'fixed',
          top: 0,
          width: '100vw',
          willChange,
          zIndex: 10,
        }}
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          gap={isMD ? 2 : 4}
          height="100%"
        >
          {navigationLinks.map((link) => (
            <Link
              key={link.to}
              onClick={toggleOpen}
              rel={link.openInNewTab ? 'noopener noreferrer' : ''}
              target={link.openInNewTab ? '_blank' : ''}
              to={link.to}
            >
              <Typography
                fontSize={30}
                fontWeight={900}
                variant="subtitle1"
              >
                {link.label}
              </Typography>
            </Link>
          ))}
          {socialLinks}
        </Box>
      </motion.div>
    </>
  )
}

export default Navigation
