import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import { alpha, useTheme } from '@mui/system'
import { Link } from 'react-router-dom'

import Relation from 'components/Relation'

import g1 from './images/g1.jpg'
import g2 from './images/g2.jpg'
import g3 from './images/g3.jpg'
import g4 from './images/g4.jpg'
import g5 from './images/g5.jpg'
import g6 from './images/g6.jpg'

const images = [g6, g4, g3, g5, g2, g1]

const OgolnopolskieBanner = () => {
  const theme = useTheme()

  const isMD = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Relation
      attention="Przyjedź na spotkanie!"
      background={alpha(theme.palette.common.black, 0.04)}
      images={images}
      title="Spotkania Ogólnopolskie"
    >
      <Typography
        textAlign={isMD ? 'center' : 'left'}
        variant="body2"
      >
        Spotkania ogólnopolskie, męskie (kiedyś zwane <b>ONLY4MEN</b>)
        organizowane przez wspólnotę Wojownicy Maryi odbywają się cyklicznie (co
        1-2 miesiące).
      </Typography>
      <Typography
        textAlign={isMD ? 'center' : 'left'}
        variant="body2"
      >
        Mają one charakter otwarty. Oprócz członków wspólnoty Wojownicy Maryi,
        uczestniczą w nich osoby z różnych środowisk. Są wśród nas mężowie,
        ojcowie, single, rozeznający, kapłani, z różnych części Polski i Świata.
      </Typography>
      <Typography
        textAlign={isMD ? 'center' : 'left'}
        variant="body2"
      >
        Spotkania te to przede wszystkim wspólna <b>Msza Święta</b>,{' '}
        <b>modlitwa</b> (różaniec, koronka do Bożego Miłosierdzia),{' '}
        <b>konferencje</b>, <b>świadectwa</b> i czas na <b>integrację</b>.
      </Typography>
      <Box
        display="flex"
        justifyContent={isMD ? 'center' : 'start'}
      >
        <Link to="/wydarzenia">
          <Button
            size="large"
            variant="outlined"
          >
            Zobacz wydarzenia
          </Button>
        </Link>
      </Box>
    </Relation>
  )
}

export default OgolnopolskieBanner
