import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import DownloadIcon from '@mui/icons-material/Download'
import LinkIcon from '@mui/icons-material/Link'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import copy from 'copy-to-clipboard'
import { find } from 'lodash'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { useToggle } from 'react-use'

import events from 'data/events'

const EventPage = () => {
  const theme = useTheme()

  const isSM = useMediaQuery(theme.breakpoints.down('sm'))

  const { id } = useParams<{ id: string }>()

  const [showCopiedToast, toggleCopiedToast] = useToggle(false)

  const event = find(events, { id })

  const handleCopyLink = () => {
    toggleCopiedToast(true)
    copy(`${location.origin}/wydarzenia/${event?.id}`)
  }

  if (!event) {
    return null
  }

  return (
    <>
      <Helmet>
        <title>{event?.name || 'Wydarzenia'} | Wojownicy Maryi</title>
        <meta
          name="description"
          content="Wojownicy Maryi"
        />
      </Helmet>
      <Container maxWidth="xl">
        <Box py={15}>
          <Grid
            container
            spacing={isSM ? 5 : 10}
            justifyContent="flex-start"
          >
            <Grid
              item
              xs={12}
              md={6}
              order={{ xs: 2, md: 1 }}
            >
              <Stack spacing={3}>
                {event?.attention && (
                  <Typography
                    fontWeight={900}
                    textAlign={isSM ? 'center' : 'left'}
                    variant="h4"
                  >
                    {event?.attention}
                  </Typography>
                )}
                <Typography
                  fontFamily="Oswald"
                  fontWeight="900"
                  textAlign={isSM ? 'center' : 'left'}
                  variant="h3"
                >
                  {event?.name}
                </Typography>
                <Box
                  alignItems="center"
                  display="flex"
                  gap={2}
                >
                  <LocationOnIcon fontSize="large" />
                  <Typography
                    fontWeight={500}
                    variant="body2"
                  >
                    {event?.location}
                  </Typography>
                </Box>
                <Box
                  alignItems="center"
                  display="flex"
                  gap={2}
                >
                  <CalendarMonthIcon fontSize="large" />
                  <Typography
                    fontWeight={500}
                    variant="body2"
                  >
                    {event.startDate}
                  </Typography>
                </Box>
                <Box
                  alignItems="center"
                  display="flex"
                  gap={2}
                >
                  <AccessTimeIcon fontSize="large" />
                  <Typography
                    fontWeight={500}
                    variant="body2"
                  >
                    {event.startTime}
                  </Typography>
                </Box>
                <Typography
                  component="div"
                  display="flex"
                  flexDirection="column"
                  fontSize={18}
                  gap={2}
                  variant="body1"
                >
                  {event.description}
                </Typography>
                <Box
                  display="flex"
                  flexDirection={isSM ? 'column' : 'row'}
                  gap={2}
                >
                  <Button
                    endIcon={<DownloadIcon />}
                    fullWidth={isSM}
                    href={event?.image || ''}
                    rel="noopener noreferrer"
                    size="large"
                    target="_blank"
                    variant="outlined"
                  >
                    Pobierz plakat
                  </Button>
                  <Button
                    endIcon={<LinkIcon />}
                    fullWidth={isSM}
                    onClick={handleCopyLink}
                    size="large"
                    variant="outlined"
                  >
                    Skopiuj link
                  </Button>
                </Box>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              order={{ xs: 1, md: 2 }}
            >
              <Box
                component="img"
                maxWidth="100%"
                src={event?.image}
                sx={{
                  borderRadius: 3,
                  boxShadow: 10,
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Snackbar
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          autoHideDuration={5000}
          onClose={toggleCopiedToast}
          open={showCopiedToast}
          style={{
            top: 60,
          }}
        >
          <Alert severity="info">
            Link do wydarzenia został skopiowany do schowka
          </Alert>
        </Snackbar>
      </Container>
    </>
  )
}

export default EventPage
