import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'
import {
  alpha,
  Box,
  Button,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { motion } from 'framer-motion'

import KrolowoMalzenstw from 'images/krolowo-malzenstw.jpg'

const SpecialBanner = () => {
  const theme = useTheme()

  const isSM = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Container
      maxWidth={false}
      sx={{
        background: `linear-gradient(${alpha(
          theme.palette.common.black,
          0.3
        )}, ${alpha(
          theme.palette.common.black,
          0.3
        )}), url(${KrolowoMalzenstw})`,
        backgroundAttachment: isSM ? 'initial' : 'fixed',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
      }}
    >
      <Container>
        <motion.div
          initial={{
            y: 100,
            scale: 0.8,
          }}
          whileInView={{
            y: 0,
            scale: 1,
          }}
          transition={{
            delay: 0.3,
            duration: 0.7,
          }}
        >
          <Stack
            alignItems="center"
            display="flex"
            justifyContent="center"
            gap={3.5}
            height="100%"
            py={25}
          >
            <LocalLibraryIcon
              sx={{
                color: theme.palette.common.white,
                fontSize: 50,
              }}
            />
            <Typography
              color="white"
              fontWeight={900}
              textAlign="center"
              variant="h2"
            >
              Materiały formacyjne
            </Typography>
            <Typography
              color="white"
              fontWeight={500}
              textAlign="center"
              variant="subtitle2"
            >
              Serdecznie zapraszamy do zapoznania się z materiałami formacyjnymi
              Wojowników Maryi. Pakiet Wojownika Maryi to zbiór polecanych przez
              nas lektur służących formacji duchowej, a w tym: "Traktat o
              prawdziwym Nabożeństwie do NMP", "Kecharitomene", "Walka duchowa u
              ojców pustyni" i wiele innych!
            </Typography>
            <Box>
              <Button
                color="secondary"
                href="https://sumuswydawnictwo.pl/pl/p/Pakiet-Wojownika-Maryi/1033"
                rel="noopener noreferrer"
                size="large"
                target="_blank"
                variant="outlined"
              >
                Pakiet Wojownika
              </Button>
            </Box>
          </Stack>
        </motion.div>
      </Container>
    </Container>
  )
}

export default SpecialBanner
