import { useMediaQuery } from '@mui/material'
import { motion } from 'framer-motion'
import { ReactNode } from 'react'

import { theme } from 'theme'

interface Props {
  children: ReactNode
  type: 'left' | 'right'
}

export const SlideIn = ({ children, type = 'left' }: Props) => {
  const isSM = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <motion.div
      initial={{
        opacity: 0,
        x: isSM ? 0 : type === 'left' ? -100 : 100,
        y: isSM ? 100 : 0,
      }}
      whileInView={{
        opacity: 1,
        x: 0,
        y: 0,
      }}
      transition={{
        delay: 0.15,
        duration: 0.7,
      }}
    >
      {children}
    </motion.div>
  )
}
