import { REGIONS } from 'consts/regions'
import { Group } from 'types/group'

import WarszawaSiekierkiImage from './images/warszawa-siekierki.jpeg'
import WarszawaUrsynowImage from './images/warszawa-ursynow.jpeg'

const groups: Group[] = [
  {
    id: 'warszawa-ursynow',
    name: 'Warszawa Ursynów',
    email: 'wojownicymaryi.ursynow@gmail.com',
    website: '',
    image: WarszawaUrsynowImage,
    regionId: REGIONS.METROPOLIA_WARSZAWSKA,
  },
  {
    id: 'warszawa-siekierki',
    name: 'Warszawa Siekierki',
    email: 'wojownicymaryi.siekierki@gmail.com',
    website: 'https://wojownicywarszawa.pl/',
    image: WarszawaSiekierkiImage,
    regionId: REGIONS.METROPOLIA_WARSZAWSKA,
  },
  {
    id: 'warszawa-bemowo',
    name: 'Warszawa Bemowo',
    email: 'wojownicymaryi.bemowo@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_WARSZAWSKA,
  },
  {
    id: 'legionowo',
    name: 'Legionowo',
    email: 'wojownicymaryi.legionowo@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_WARSZAWSKA,
  },
  {
    id: 'sierpc',
    name: 'Sierpc',
    email: 'wojownicymaryi.sierpc@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_WARSZAWSKA,
  },
  {
    id: 'radzymin',
    name: 'Radzymin',
    email: 'wojownicymaryi.radzymin@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_WARSZAWSKA,
  },
  {
    id: 'pultusk',
    name: 'Pułtusk',
    email: 'wojownicymaryi.pultusk@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_WARSZAWSKA,
  },
  {
    id: 'plonsk',
    name: 'Płońsk',
    email: 'wojownicymaryi.plonsk@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_WARSZAWSKA,
  },
  {
    id: 'plock',
    name: 'Płock',
    email: 'wojownicymaryi.plock@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_WARSZAWSKA,
  },
  {
    id: 'otwock',
    name: 'Otwock',
    email: 'wojownicymaryi.otwock@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_WARSZAWSKA,
  },
  {
    id: 'niepokalanow',
    name: 'Niepokalanów',
    email: 'wm.niepokalanow@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_WARSZAWSKA,
  },
  {
    id: 'ciechanow',
    name: 'Ciechanów',
    email: 'wojownicymaryi.ciechanow@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_WARSZAWSKA,
  },
]

export default groups
