import { Event } from 'types/event'

import events_01_2024 from './01-2024'
import events_02_2024 from './02-2024'
import events_03_2024 from './03-2024'
import events_04_2024 from './04-2024'
import events_05_2024 from './05-2024'
import events_06_2024 from './06-2024'

const events: Event[] = [
  ...events_01_2024,
  ...events_02_2024,
  ...events_03_2024,
  ...events_04_2024,
  ...events_05_2024,
  ...events_06_2024,
]

export default events
