import {
  Box,
  Grid,
  Icon,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Container } from '@mui/system'
import { FaPrayingHands } from 'react-icons/fa'
import { MdFamilyRestroom } from 'react-icons/md'

const FormacjaBanner = () => {
  const theme = useTheme()

  const isSM = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Container maxWidth="xl">
      <Grid
        container
        py={10}
        spacing={4}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            gap={2}
          >
            <Icon fontSize="large">
              <FaPrayingHands />
            </Icon>
            <Typography
              fontFamily="Oswald"
              fontWeight={900}
              textAlign={isSM ? 'center' : 'left'}
              variant="h3"
            >
              Formacja duchowa
            </Typography>
            <Typography textAlign={isSM ? 'center' : 'left'}>
              Każdy pasowany Wojownik Maryi zobowiązany jest:
            </Typography>
            <Box
              component="ul"
              fontSize="large"
            >
              <li>odmawiać codziennie 1 część różańca świętego</li>
              <li>
                odbyć 33-dniowe rekolekcje przygotowujące do zawierzenia
                Jezusowi przez ręce Maryi, oraz dokonać takiego aktu zawierzenia
              </li>
              <li>modlić się za kapłanów</li>
              <li>
                brać czynny udział w życiu sakramentalnym (spowiedź,
                Eucharystia, Komunia, Adoracja)
              </li>
              <li>
                czynić akty pokutne np. poprzez modlitwę krzyżem, wyrzeczenia
              </li>
              <li>uczestniczyć czynnie w życiu wspólnoty i Kościoła</li>
              <li>
                uczestniczyć w wynagradzających nabożeństwach pierwszych sobót
                miesiąca
              </li>
              <li>
                zapoznawać się z: Katechizmem Kościoła Katolickiego, systemem
                prewencyjnym św. Jana Bosko, materiałami apologetycznymi i
                propozycjami książek przesłanymi do liderów oraz z modlitwą
                Puklerz św. Patryka
              </li>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            gap={2}
          >
            <Icon fontSize="large">
              <MdFamilyRestroom />
            </Icon>
            <Typography
              fontFamily="Oswald"
              fontWeight={900}
              textAlign={isSM ? 'center' : 'left'}
              variant="h3"
            >
              Formacja ludzka
            </Typography>
            <Typography textAlign={isSM ? 'center' : 'left'}>
              Każdy pasowany Wojownik Maryi zobowiązany jest dbać o:
            </Typography>
            <Box
              component="ul"
              fontSize="large"
            >
              <li>budowanie relacji w rodzinie</li>
              <li>budowanie relacji we wspólnocie i po za nią</li>
              <li>uczestniczyć czynnie w życiu wspólnoty i kościoła</li>
              <li>
                dyscyplinę i rozwój fizyczny, poprzez aktywności (np. sport)
              </li>
              <li>dawanie dobrego świadectwa swoja postawą</li>
              <li>nie dawanie antyświadectwa po za wspólnotą</li>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default FormacjaBanner
