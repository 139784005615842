import { REGIONS } from 'consts/regions'
import { Group } from 'types/group'

const groups: Group[] = [
  {
    id: 'augustow',
    name: 'Augustów',
    email: 'wojownicymaryi.elblag@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_WARMINSKA,
  },
  {
    id: 'elk',
    name: 'Ełk',
    email: 'wojownicymaryi.elk@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_WARMINSKA,
  },
  {
    id: 'elblag',
    name: 'Elbląg',
    email: 'wojownicymaryi.elblag@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_WARMINSKA,
  },
  {
    id: 'iława',
    name: 'Iława',
    email: 'wojownicymaryi.ilawa@gmial.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_WARMINSKA,
  },
  {
    id: 'ketrzyn',
    name: 'Kętrzyn',
    email: 'wojownicymaryi.ketrzyn@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_WARMINSKA,
  },
  {
    id: 'olsztyn',
    name: 'Olsztyn',
    email: 'wojownicymaryi.olsztyn@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_WARMINSKA,
  },
]

export default groups
