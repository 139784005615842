import { ThemeProvider } from '@mui/material/styles'
import 'lightgallery/scss/lg-thumbnail.scss'
import 'lightgallery/scss/lg-zoom.scss'
import 'lightgallery/scss/lightgallery.scss'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import 'slick-carousel/slick/slick-theme.scss'
import 'slick-carousel/slick/slick.scss'

import { theme } from 'theme'

import App from './App'

import './styles/globals.scss'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const Index = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </React.StrictMode>
  )
}

root.render(<Index />)
