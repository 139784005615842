import { REGIONS } from 'consts/regions'
import { Group } from 'types/group'

const groups: Group[] = [
  {
    id: 'brodnica',
    name: 'Brodnica',
    email: 'wojownicymaryi.brodnica@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_GDANSKA,
  },
  {
    id: 'gdansk-niepokalana',
    name: 'Gdańsk-Niepokalana',
    email: 'wojownicymaryi.gda.niepokalana@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_GDANSKA,
  },
  {
    id: 'gdansk-kokoszki',
    name: 'Gdańsk-Kokoszki',
    email: 'wojownicymaryi.gdansk@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_GDANSKA,
  },
  {
    id: 'gdansk-kokoszki',
    name: 'Gdańsk-Kokoszki',
    email: 'wojownicymaryi.gdansk@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_GDANSKA,
  },
  {
    id: 'gdynia',
    name: 'Gdynia',
    email: 'wojownicymaryi.gdynia@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_GDANSKA,
  },
  {
    id: 'grudziadz',
    name: 'Grudziądz',
    email: 'wojownicymaryi.grudziadz@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_GDANSKA,
  },
  {
    id: 'starogard-gdanski',
    name: 'Starogard Gdański',
    email: 'wojownicymaryi.starogardgdanski@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_GDANSKA,
  },
  {
    id: 'kartuzy',
    name: 'Kartuzy',
    email: 'wojownicymaryi.kartuzy@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_GDANSKA,
  },
  {
    id: 'rumia',
    name: 'Rumia',
    email: 'wojownicymaryi.rumia@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_GDANSKA,
  },
  {
    id: 'torun',
    name: 'Toruń',
    email: 'wojownicymaryi.torun@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_GDANSKA,
  },
]

export default groups
