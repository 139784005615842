import { Button } from '@mui/material'
import { Link } from 'react-router-dom'

import KimJestWojownikMaryi from 'pages/components/KimJestWojownikMaryi'

import BookAdvert from './BookAdvert'
import MainBanner from './MainBanner'
import OpiekunBanner from './OpiekunBanner'
import RelacjaWydarzeniaBanner from './RelacjaWydarzeniaBanner'
import SpecialBanner from './SpecialBanner'
import UpcomingEvents from './UpcomingEvents'

const HomePage = () => {
  return (
    <>
      <MainBanner />
      <KimJestWojownikMaryi>
        <Link to="/kodex">
          <Button
            size="large"
            variant="outlined"
          >
            Nasz Kodex
          </Button>
        </Link>
      </KimJestWojownikMaryi>
      <OpiekunBanner />
      <BookAdvert />
      <SpecialBanner />
      <UpcomingEvents />
      <RelacjaWydarzeniaBanner />
    </>
  )
}

export default HomePage
