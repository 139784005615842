import { REGIONS } from 'consts/regions'
import { Region } from 'types/region'

const regions: Region[] = [
  {
    id: REGIONS.METROPOLIA_WROCLAWSKA,
    name: 'Metropolia Wrocławska',
    leaderName: 'Piotr Grobelny',
  },
  {
    id: REGIONS.METROPOLIA_WARSZAWSKA,
    name: 'Metropolia Warszawska',
    leaderName: 'Michał Modzelan',
  },
  {
    id: REGIONS.METROPOLIA_WARMINSKA,
    name: 'Metropolia Warmińska',
    leaderName: 'Paweł Martynowicz',
  },
  {
    id: REGIONS.METROPOLIA_SZCZECINSKO_KAMIENSKA,
    name: 'Metropolia Szczecińsko-Kamieńska',
    leaderName: 'Radek Bień',
  },
  {
    id: REGIONS.METROPOLIA_PRZEMYSKA,
    name: 'Metropolia Przemyska',
    leaderName: 'Piotr Ryfa',
  },
  {
    id: REGIONS.METROPOLIA_POZNANSKA,
    name: 'Metropolia Poznańska',
    leaderName: 'Mariusz Starosta',
  },
  {
    id: REGIONS.METROPOLIA_LUBELSKA,
    name: 'Metropolia Lubelska',
    leaderName: 'Jarosław Cieślak',
  },
  {
    id: REGIONS.METROPOLIA_LODZKA,
    name: 'Metropolia Łódzka',
    leaderName: 'Tomasz Ratecki',
  },
  {
    id: REGIONS.METROPOLIA_KRAKOWSKA,
    name: 'Metropolia Krakowska',
    leaderName: 'Paweł Kluza',
  },
  {
    id: REGIONS.METROPOLIA_GNIEZNIENSKA,
    name: 'Metropolia Gnieźnieńska',
    leaderName: 'Witold Mikołajczak',
  },
  {
    id: REGIONS.METROPOLIA_GDANSKA,
    name: 'Metropolia Gdańska',
    leaderName: 'Michał Makurat',
  },
  {
    id: REGIONS.METROPOLIA_CZESTOCHOWSKA,
    name: 'Metropolia Częstochowska',
    leaderName: 'Tomasz Ratecki',
  },
  {
    id: REGIONS.METROPOLIA_BIALOSTOCKA,
    name: 'Metropolia Białostocka',
    leaderName: 'Grzegorz Skorupski',
  },
  {
    id: REGIONS.GRUPY_ZAGRANICZNE,
    name: 'Grupy zagraniczne',
    leaderName: 'Andrzej Grabowski',
  },
]

export default regions
