import { REGIONS } from 'consts/regions'
import { Group } from 'types/group'

const groups: Group[] = [
  {
    id: 'boguchwala',
    name: 'Boguchwała',
    email: 'wojownicymaryi.boguchwala@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_PRZEMYSKA,
  },
  {
    id: 'krasnobrod',
    name: 'Krasnobród',
    email: 'wojownicymaryi.krasnobrod@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_PRZEMYSKA,
  },
  {
    id: 'krosno',
    name: 'Krosno',
    email: 'wm.krosno@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_PRZEMYSKA,
  },
  {
    id: 'przemysl',
    name: 'Przemyśl',
    email: 'wojownicymaryi.przemysl@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_PRZEMYSKA,
  },
  {
    id: 'przeworsk',
    name: 'Przeworsk',
    email: 'wojownicymaryi.przeworsk@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_PRZEMYSKA,
  },
  {
    id: 'rzeszow',
    name: 'Rzeszów',
    email: 'wojownicymaryi.rzeszow@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_PRZEMYSKA,
  },
  {
    id: 'ustrzyki-dolne',
    name: 'Ustrzyki Dolne',
    email: 'wojownicymaryi.ustrzykidolne@gmail.com',
    website: '',
    image: '',
    regionId: REGIONS.METROPOLIA_PRZEMYSKA,
  },
]

export default groups
