import { common, grey } from '@mui/material/colors'
import { alpha, createTheme } from '@mui/material/styles'

const primaryMainColor = grey[900]

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 900,
          paddingLeft: 32,
          paddingRight: 32,
          whiteSpace: 'nowrap',
          '&, &:hover': {
            borderWidth: 3,
          },
        },
        sizeLarge: {
          fontSize: 18,
          height: 56,
        },
        outlinedPrimary: {
          borderColor: primaryMainColor,
          '&:hover': {
            backgroundColor: primaryMainColor,
            color: common.white,
          },
        },
        outlinedSecondary: {
          backgroundColor: alpha(common.white, 0.5),
          borderColor: common.white,
          '&:hover': {
            backgroundColor: common.white,
            color: primaryMainColor,
          },
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        fontSizeLarge: {
          fontSize: 48,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: primaryMainColor,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        endAdornment: {
          display: 'flex',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          backgroundColor: primaryMainColor,
          fontSize: 16,
          color: common.white,
          svg: {
            color: common.white,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: primaryMainColor,
    },
    secondary: {
      main: common.white,
    },
  },
  typography: {
    fontFamily: 'Raleway',
  },
})

theme.typography.h1 = {
  fontSize: '6rem',
  fontWeight: 900,
  [theme.breakpoints.down('sm')]: {
    fontSize: '3.5rem',
  },
}

theme.typography.h2 = {
  fontSize: '3.75rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.75rem',
  },
  ...theme.typography.h2,
}

theme.typography.h3 = {
  color: grey[400],
  ...theme.typography.h3,
}

theme.typography.h4 = {
  ...theme.typography.h4,
  color: grey[400],
}

theme.typography.subtitle1 = {
  fontSize: '1.6rem',
}

theme.typography.subtitle2 = {
  fontFamily: 'Oswald',
  fontSize: '1.6rem',
}

theme.typography.body1 = {
  fontSize: '1.4rem',
}

theme.typography.body2 = {
  fontSize: '1.2rem',
}

export { theme }
